import {
  Backdrop, Button, Card, CardContent, Slide,
} from '@material-ui/core';
import { StyledComponentProps, createStyles, withStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CookiePolicy } from '../../models/CookiePolicy';

interface Props {
  cookiePolicy: CookiePolicy;
  acceptCookies: () => void;
  removeAnalyticsCookie: () => void;
}

interface State {
  backDrop: boolean;
  backDropAcceptedCookies: boolean;
  cookiePreferencesRedirect: boolean;
  width: number
}

const styles = () => createStyles({
  cookieBar: {
    top: '0%',
    width: '100%',
    margin: 0,
    backgroundColor: 'rgba(255, 255, 255)',
    color: '#FFF',
    position: 'absolute',
  },
  content: {
    '@media (min-width: 375px)': {
      paddingRight: '25px !important',
    },
    '@media (min-width: 425px)': {
      paddingRight: '50px !important',
    },
    '@media (min-width: 768px)': {
      padding: '12px 150px !important',
    },
    margin: '0 auto',
  },
  disclaimer: {
    '@media (min-width: 320px)': {
      fontSize: '24px',
    },
    '@media (min-width: 425px)': {
      fontSize: '26px',
    },
    '@media (min-width: 768px)': {
      fontSize: '28px',
    },
    color: '#2C7BCC',
    lineHeight: '34px',
    fontWeight: 300,
    wordWrap: 'break-word',
  },
  text: {
    '@media (min-width: 320px)': {
      fontSize: '16px',
    },
    '@media (min-width: 768px)': {
      fontSize: '18px',
    },
    display: 'inline-block',
    color: '#58585a',
    marginBottom: '2rem',
    lineHeight: '26px',
    wordWrap: 'break-word',
  },
  link: {
    display: 'inline-block',
    fontSize: '18px',
    lineHeight: '26px',
    textTransform: 'none',
  },
  buttons: {
    '@media (min-width: 320px)': {
      fontSize: '12px',
    },
    '@media (min-width: 425px)': {
      fontSize: '14px',
    },
    '@media (min-width: 768px)': {
      fontSize: '16px',
    },
    marginRight: '13px',
    backgroundColor: '#4a851f',
    marginBottom: '1rem',
    fontWeight: 700,
    height: '40px',
    lineHeight: '40px',
    borderRadius: '4px',
    '& .MuiButton-label': {
      '@media (min-width: 320px)': {
        marginLeft: '0',
        marginRight: '0',
      },
      '@media (min-width: 425px)': {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
      },
      '@media (min-width: 768px)': {
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    },
    '&:hover': {
      backgroundColor: '#025c1a',
    },
    color: 'white',
    textTransform: 'none',
  },
  backdrop: {
    zIndex: 1,
    color: '#FFF',
  },
  cookieLink: {
    textDecoration: 'none',
  },
});

class CustomerFlowGDPRCookieBar extends Component<Props & StyledComponentProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      backDrop: true,
      backDropAcceptedCookies: false,
      cookiePreferencesRedirect: false,
      width: 0,
    };
    this.acceptCookies = this.acceptCookies.bind(this);
    this.closeCookieBanner = this.closeCookieBanner.bind(this);
    this.goToCookiePreferences = this.goToCookiePreferences.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public UNSAFE_componentWillMount() {
    if (this.props.cookiePolicy && this.props.cookiePolicy.analytics) {
      this.setState({ backDrop: false, backDropAcceptedCookies: false });
      this.props.acceptCookies();
    }
    if (!this.props.cookiePolicy || (this.props.cookiePolicy && !this.props.cookiePolicy.analytics)) {
      this.props.removeAnalyticsCookie();
    }
  }

  public render() {
    const classes = this.props.classes!;
    if (!this.props.cookiePolicy || !this.props.cookiePolicy.essential) {
      return <>{this.cookieBanner(classes)}</>;
    }
    return <></>;
  }

  private cookieBanner(classes) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={this.state.backDrop} aria-hidden="false" style={{ width: this.state.width }}>
          <Slide
            direction="down"
            in
            mountOnEnter
            unmountOnExit
            aria-modal="true"
            aria-describedby="modal_desc"
          >
            <Card className={classes.cookieBar}>
              <CardContent className={classes.content}>
                <h1 className={classes.disclaimer}>
                  <Trans values={{ domain: document.location.hostname }}>gdprCookieUrl</Trans>
                </h1>
                <p className={classes.text} id="modal_desc">
                  <Trans i18nKey="gdprCookieBody">
                    <Link className={classes.cookieLink} id="cookiePolicy" to="/cookiepolicy" />
                  </Trans>
                </p>
                <br />
                <br />
                <Button
                  id="acceptCookies"
                  variant="contained"
                  onClick={this.acceptCookies}
                  className={classes.buttons}
                  endIcon={<ChevronRight />}
                >
                  <Trans i18nKey="gdprCookieAcknowledge" />
                </Button>
              </CardContent>
            </Card>
          </Slide>
        </Backdrop>
        {this.state.cookiePreferencesRedirect ? <Redirect to="/cookiepreferences" /> : null}
      </>
    );
  }

  private acceptCookies() {
    this.setState({ backDrop: false, backDropAcceptedCookies: true });
    this.props.acceptCookies();
  }

  private closeCookieBanner() {
    this.setState({ backDrop: false, backDropAcceptedCookies: false });
  }

  private goToCookiePreferences() {
    this.setState({ backDrop: false, backDropAcceptedCookies: false, cookiePreferencesRedirect: true });
  }

  private updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
}

export default withStyles(styles)(CustomerFlowGDPRCookieBar);
