import { Link, Typography } from '@material-ui/core';
import * as React from 'react';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CustomerAppState } from '../../reducers/customer_ui';

const useStyles = makeStyles({
  subtitle: {
    color: '#2c7bbc',
    fontSize: '28px',
    fontWeight: 300,
  },
});

interface StateProps {
  country?: string;
}

type Props = StateProps;

function CookiePolicyContactInfo(props: Props): React.JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { country } = props;

  if (country === 'CAN') {
    // Elavon Contacts
    const elavonUrl = t('cookie_policy_contact_methods_web_url');
    const elavonPhone = t('cookie_policy_contact_methods_tel_num');
    const elavonEmail = t('cookie_policy_contact_methods_email_addr');
    // Commissioner Contacts
    const tollNum = t('cookie_policy_contact_commissioner_tel_toll_num');
    const phoneNum = t('cookie_policy_contact_commissioner_tel_phone_num');
    const faxNum = t('cookie_policy_contact_commissioner_tel_fax_num');
    const ttyNum = t('cookie_policy_contact_commissioner_tel_tty_num');
    const commUrl = t('cookie_policy_contact_commissioner_url');

    return (
      <>
        <Typography className={classes.subtitle} paragraph>
          {t('cookie_policy_contact_title_can')}
        </Typography>
        <Typography paragraph>
          {t('cookie_policy_contact_intro')}
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              <Trans
                i18nKey="cookie_policy_contact_methods_web"
                values={{ url: elavonUrl }}
              >
                <Link href={`https://${elavonUrl}`} target="_blank">
                  {elavonUrl}
                </Link>
              </Trans>
            </li>
            <li>
              <Trans
                i18nKey="cookie_policy_contact_methods_tel"
                values={{ num: elavonPhone }}
              >
                <Link href={`tel:${elavonPhone}`}>{elavonPhone}</Link>
              </Trans>
            </li>
            <li>
              <Trans
                i18nKey="cookie_policy_contact_methods_email"
                values={{ email: elavonEmail }}
              >
                <Link href={`mailto:${elavonEmail}`}>{elavonEmail}</Link>
              </Trans>
            </li>
            <li>{t('cookie_policy_contact_methods_mail')}</li>
          </ul>
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_contact_address_can" />
        </Typography>
        <Typography paragraph>
          <Trans
            i18nKey="cookie_policy_contact_commissioner"
            values={{ url: commUrl }}
          >
            <Link href={commUrl} target="_blank" rel="noopener">
              {commUrl}
            </Link>
          </Trans>
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_contact_commissioner_info_centre" />
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_contact_commissioner_tel" />
        </Typography>
        <Typography>
          <Trans
            i18nKey="cookie_policy_contact_commissioner_tel_toll"
            values={{ num: tollNum }}
          >
            <Link href={`tel:${tollNum}`}>{tollNum}</Link>
          </Trans>
        </Typography>
        <Typography>
          <Trans
            i18nKey="cookie_policy_contact_commissioner_tel_phone"
            values={{ num: phoneNum }}
          >
            <Link href={`tel:${phoneNum}`}>{phoneNum}</Link>
          </Trans>
        </Typography>
        <Typography>
          <Trans
            i18nKey="cookie_policy_contact_commissioner_tel_fax"
            values={{ num: faxNum }}
          >
            <Link href={`tel:${faxNum}`}>{faxNum}</Link>
          </Trans>
        </Typography>
        <Typography>
          <Trans
            i18nKey="cookie_policy_contact_commissioner_tel_tty"
            values={{ num: ttyNum }}
          >
            <Link href={`tel:${ttyNum}`}>{ttyNum}</Link>
          </Trans>
        </Typography>
      </>
    );
  }

  if (country === 'USA') {
    return (
      <>
        <Typography className={classes.subtitle} paragraph>
          {t('cookie_policy_contact_title')}
        </Typography>
        <Typography paragraph>
          {t('cookie_policy_contact_body')}
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_contact_address" />
          <br />
          <Link href={`mailto:${t('cookie_policy_contact_email')}`}>
            {t('cookie_policy_contact_email')}
          </Link>
        </Typography>
      </>
    );
  }

  const gdprEmail = t('cookie_policy_email_address');
  return (
    <>
      <Typography paragraph>
        <strong>
          <Trans
            i18nKey="cookie_policy_email_address_section"
            values={{ email: gdprEmail }}
          >
            <Link href={`mailto:${gdprEmail}`}>{gdprEmail}</Link>
          </Trans>
        </strong>
      </Typography>
      <Typography paragraph>
        <Trans i18nKey="cookie_policy_address_1" />
      </Typography>
    </>
  );
}

const mapStateToProps = (state: CustomerAppState): StateProps => ({
  country: state.JurisdictionInfo.country,
});

export default connect(mapStateToProps)(CookiePolicyContactInfo);
